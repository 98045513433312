import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { ChatSlice, createChatSlice } from "./chatSlice";
import { persistOptions } from "./persist";

export type State = ChatSlice;

export const useRootState = create<State>()(
  devtools(
    persist(
      immer((...args) => {
        return {
          ...createChatSlice(...args),
        };
      }),
      persistOptions
    )
  )
);
