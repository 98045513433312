import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Message, reCAPTCHAScriptURL, Sender } from "@/constants/constants";
import { useRootState } from "@/state/root";
import { UserInputMessage } from "@/types";
import {
  removeRecaptchaV2Container,
  renderRecaptchaV2,
  setV2_token,
} from "@/utils/CAPTCHA";
import { getChatHistory } from "@/utils/fetcher";
import { handleReceiveBotMsgFactory } from "@/utils/handleReceiveBotMsgFactory";
import { handleUserSendMsgFactory } from "@/utils/handleUserSendMsgFactory";
import {
  botMessageFactory,
  localMsgFactory,
  userMessageFactory,
} from "@/utils/messageFactory";
import { scrollToBottom } from "@/utils/scrollToBottom";
import { initSocket, sendMsgSocketFactory } from "@/utils/sendMsgSocketFactory";
import { getUserId } from "@/utils/userid";
import { addScriptToBody } from "@/utils/utils";

import ChatHeader from "./components/ChatHeader";
import ChatInput from "./components/ChatInput";
import ChatMessages from "./components/ChatMessage";
import ToolBar from "./components/ToolBar";

interface ChatAreaProps {
  handleCloseBubble: () => void;
}

const ChatArea: React.FC<ChatAreaProps> = ({ handleCloseBubble }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [history, setHistory] = useState<Message[]>([]);
  const isThereHistory = React.useMemo(() => history.length > 0, [history]);
  const [isResolveRecaptcha, setIsResolveRecaptcha] = useState(true);
  const socket = sendMsgSocketFactory();
  const [
    userInputMessage,
    shouldSendMessage,
    messageProcessing,
    setShouldSendMessage,
    setMessageProcessing,
    shouldAutoScroll,
  ] = useRootState((state) => [
    state.userInputMessage,
    state.shouldSendMessage,
    state.messageProcessing,
    state.setShouldSendMessage,
    state.setMessageProcessing,
    state.shouldAutoScroll,
  ]);

  const socketHandleSendMessage = React.useMemo(
    () => handleUserSendMsgFactory(socket),
    [socket]
  );

  const handleSendMessage = React.useCallback(
    ({ message, cache }: UserInputMessage): boolean => {
      if (message === "") {
        setShouldSendMessage(false);
        return false;
      }

      if (messageProcessing) {
        toast("⚠️ Please wait for the bot to respond");
        setShouldSendMessage(false);
        return false;
      }

      if (socket.readyState !== 1) {
        toast("⚠️ Connection not ready");
        setShouldSendMessage(false);
        return false;
      }

      if (!isResolveRecaptcha) {
        localMsgFactory(
          t("please resolve reCAPTCHA first"),
          Sender.BOT,
          setMessages
        );
        setShouldSendMessage(false);
        return false;
      }
      const userMessage = userMessageFactory(message);
      const botMessage = botMessageFactory("");
      setMessages((prevMessages: Message[]) => [
        ...prevMessages,
        userMessage,
        botMessage,
      ]);
      setMessageProcessing(true);
      socketHandleSendMessage(message, cache);
      setV2_token(undefined);
      removeRecaptchaV2Container(setMessages);
      setShouldSendMessage(false);
      return true;
    },
    [
      socketHandleSendMessage,
      isResolveRecaptcha,
      socket.readyState,
      t,
      setShouldSendMessage,
      setMessageProcessing,
      messageProcessing,
    ]
  );

  const handleExpand = React.useCallback(async () => {
    setMessages((prevMessages: Message[]) => [...history, ...prevMessages]);
  }, [history]);

  const reCAPTCHAv2Callback = (token: string) => {
    setV2_token(token);
    setIsResolveRecaptcha(true);
  };

  const reCAPTCHAv2ExpiredCallback = () => {
    setV2_token(undefined);
    setIsResolveRecaptcha(false);
  };

  useEffect(() => {
    let toastId: string | undefined;
    const timer = setTimeout(() => {
      if (socket.readyState === socket.CONNECTING) {
        toastId = toast.loading("Connecting...");
      } else if (socket.readyState === socket.CLOSED) {
        toastId = toast.error("Connection closed.");
      } else {
        toast.dismiss(toastId);
      }
    }, 0);

    return () => {
      toast.dismiss(toastId);
      clearTimeout(timer);
    };
  }, [socket.readyState, socket.CONNECTING, socket.CLOSED]);

  useEffect(() => {
    if (shouldAutoScroll) {
      scrollToBottom(containerRef);
    }
  }, [messages.length, shouldAutoScroll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      initSocket(socket);
    }, 0);

    const renderRecaptcha = () => {
      setV2_token(undefined);
      setIsResolveRecaptcha(false);
      renderRecaptchaV2(reCAPTCHAv2Callback, reCAPTCHAv2ExpiredCallback);
    };

    const removeHandler = handleReceiveBotMsgFactory(
      socket,
      containerRef,
      setMessages,
      renderRecaptcha
    );

    return () => {
      clearTimeout(timer);
      removeHandler();
    };
  }, [socket]);

  useEffect(() => {
    const removeScript = addScriptToBody(reCAPTCHAScriptURL);

    getChatHistory(getUserId()).then((data) => {
      const historyMsg = data.history.reduce(
        (
          res: Message[],
          item: {
            answer: string;
            user_question: string;
          }
        ) => [
          ...res,
          userMessageFactory(item.user_question),
          botMessageFactory(item.answer),
        ],
        []
      );
      setHistory(historyMsg);
    });
    return () => {
      removeScript();
      setHistory([]);
    };
  }, []);

  useEffect(() => {
    if (shouldSendMessage) {
      handleSendMessage(userInputMessage);
    }
  }, [
    userInputMessage,
    shouldSendMessage,
    messageProcessing,
    handleSendMessage,
  ]);

  return (
    <div
      className={`flex h-full w-full flex-auto flex-col overflow-hidden rounded-[10px] border border-[#DDE2F0] bg-white shadow-lg `}
    >
      <ChatHeader handleCloseBubble={handleCloseBubble} />

      <ChatMessages
        messages={messages}
        containerRef={containerRef}
        expandHistory={handleExpand}
        isThereHistory={isThereHistory}
      />

      <ToolBar setMessages={setMessages} />

      <ChatInput />
    </div>
  );
};

export default ChatArea;
