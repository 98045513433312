import React from "react";

import { Message, Sender } from "@/constants/constants";

import ChatBotAnswer from "./components/ChatBotAnswer";
import { ChatHistory } from "./components/ChatHistory";
import GuessQuestion from "./components/GuessQuestion";
import { Timestamp } from "./components/Timestamp";
import UserQuestion from "./components/UserQuestion";

const getCurrentDate = (): string => {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getHours()}:${currentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}  ${currentDate.toLocaleString(undefined, {
    month: "short",
    day: "numeric",
  })}`;
  return formattedDate;
};

interface ChatMessagesProps {
  messages: Message[];
  containerRef: React.RefObject<HTMLDivElement>;
  expandHistory: () => void;
  isThereHistory: boolean;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  containerRef,
  expandHistory,
  isThereHistory,
}) => {
  const [visible, setIsVisible] = React.useState(true);
  const formattedDate = React.useMemo(() => getCurrentDate(), []);

  const expand = React.useCallback(() => {
    setIsVisible(false);
    expandHistory();
  }, [expandHistory]);

  return (
    <div
      className="flex flex-auto flex-col overflow-y-auto bg-chat-bg px-5 pt-5 scrollbar scrollbar-thumb-[#DDE2F0] scrollbar-w-[6px]"
      ref={containerRef}
    >
      <ChatHistory visible={visible && isThereHistory} expand={expand} />
      <Timestamp formattedDate={formattedDate} />
      {messages.map((message, index) => {
        if (message.sender === Sender.USER) {
          return <UserQuestion key={message.id} message={message} />;
        }

        if (message.type === "question") {
          return <GuessQuestion key={message.id} message={message} />;
        } else if (message.type === "answer") {
          return (
            <ChatBotAnswer
              key={message.id}
              message={message}
              isLast={index === messages.length - 1}
            />
          );
        }
      })}
    </div>
  );
};

export default ChatMessages;
