import { Message } from "@/constants/constants";

import ReCaptchaBadge from "./components/ReCaptchaBadge";
import SwitchLangButton from "./components/SwitchLangButton";

interface ToolBarProps {
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const ToolBar: React.FC<ToolBarProps> = ({ setMessages }) => {
  return (
    <div className="flex h-12 items-center justify-end gap-4 p-3">
      <ReCaptchaBadge />
      <SwitchLangButton setMessages={setMessages} />
    </div>
  );
};
export default ToolBar;
