import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { LanguageIcon } from "@/components/icons";
import { Message } from "@/constants/constants";
import { useRootState } from "@/state/root";
import { getLang, saveLang, updateServerLang } from "@/utils/i18n";
import { botMessageFactory } from "@/utils/messageFactory";
import { sendMsgSocketFactory } from "@/utils/sendMsgSocketFactory";

interface SwitchLangButtonProps {
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const SwitchLangButton: React.FC<SwitchLangButtonProps> = ({ setMessages }) => {
  const { t, i18n } = useTranslation();
  const socket = sendMsgSocketFactory();
  const [setMessageProcessing, messageProcessing] = useRootState((state) => [
    state.setMessageProcessing,
    state.messageProcessing,
  ]);

  const handleSwitchLang = () => {
    if (socket.readyState !== 1) {
      toast("⚠️ Connection not ready");
      return;
    }

    if (messageProcessing) {
      toast("⚠️ Please wait for the bot to respond");
      return;
    }

    const newLang = i18n.language === "en" ? "ja" : "en";
    i18n.changeLanguage(newLang);
    saveLang(newLang);
    const botMessage = botMessageFactory("");
    setMessages((prevMessages) => [...prevMessages, botMessage]);
    setMessageProcessing(true);
    updateServerLang(socket);
  };

  useEffect(() => {
    const initLang = () => {
      const lang = getLang();
      i18n.changeLanguage(lang);
    };

    initLang();
  }, [i18n]);

  return (
    <button
      onClick={handleSwitchLang}
      className="flex h-5 items-center gap-1 rounded-[4px] border border-[#0F82FF] px-[10px] py-[2px] text-[12px] font-[400] text-[#0F82FF] "
    >
      <LanguageIcon />
      <span>{t("language")}</span>
    </button>
  );
};
export default SwitchLangButton;
