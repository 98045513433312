import { TOKEN, WEBSOCKET_URL } from "@/constants/constants";

import { updateServerLang } from "./i18n";
import { getUserId } from "./userid";

let socket: WebSocket | null = null;

export const sendMsgSocketFactory = (): WebSocket => {
  if (!socket) {
    socket = new WebSocket(WEBSOCKET_URL);
  }

  return socket;
};

export const handleOpenSocketEvent = (socket: WebSocket) => (): void => {
  const body = JSON.stringify({
    auth: TOKEN,
    uid: getUserId(),
  });

  socket.send(body);
  updateServerLang(socket);
};

export const initSocket = (
  socket: WebSocket,
  handler = handleOpenSocketEvent(socket)
) => {
  socket.addEventListener("open", handler);
};
