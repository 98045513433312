import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { SendIcon } from "@/components/icons";
import { useRootState } from "@/state/root";

import DotsComponent from "./components/DotsComponent";

const ChatInput = () => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");

  const [setUserInputMessage, setShouldSendMessage, messageProcessing] =
    useRootState((state) => [
      state.setUserInputMessage,
      state.setShouldSendMessage,
      state.messageProcessing,
    ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (
        !messageProcessing &&
        e.key === "Enter" &&
        !e.shiftKey &&
        !e.nativeEvent.isComposing
      ) {
        e.preventDefault();
        setShouldSendMessage(true);
      }
    },
    [messageProcessing, setShouldSendMessage]
  );

  const handleClickButton = useCallback(() => {
    if (!messageProcessing) {
      setShouldSendMessage(true);
    }
  }, [messageProcessing, setShouldSendMessage]);

  useEffect(() => {
    setUserInputMessage(inputValue, false);
  }, [inputValue, setUserInputMessage]);

  useEffect(() => {
    if (messageProcessing) {
      setInputValue("");
    }
  }, [messageProcessing]);

  return (
    <div className="flex gap-2 p-2 pt-0 ">
      <textarea
        className="disable-zoom h-[76px] flex-1 resize-none overflow-auto rounded-[10px] border border-[#9CA3AF] p-3 outline-none"
        placeholder={t("type your message here") as string}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      {!messageProcessing ? (
        <button
          className="flex items-center hover:opacity-50"
          onClick={handleClickButton}
        >
          <SendIcon />
        </button>
      ) : (
        <DotsComponent />
      )}
    </div>
  );
};

export default ChatInput;
