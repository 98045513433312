import { Message } from "@/constants/constants";

interface UserQuestionProps {
  message: Message;
}

const UserQuestion: React.FC<UserQuestionProps> = ({ message }) => {
  return (
    <div className={"flex justify-end gap-3 "}>
      <div className={"mb-8 ml-12 rounded-lg bg-ask-bg-color p-2"}>
        {message.content}
      </div>
    </div>
  );
};
export default UserQuestion;
