import "highlight.js/styles/github.css";
import "github-markdown-css";

import { useEffect, useMemo, useRef } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeHighlight from "rehype-highlight/lib";
import remarkGfm from "remark-gfm";

interface MarkDownRenderProps {
  children: string;
  isTyping?: boolean;
}

const MarkdownRender: React.FC<MarkDownRenderProps> = ({
  children,
  isTyping,
}) => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const textContainer = textContainerRef.current;
    const cursorElement = cursorRef.current;

    if (!textContainer || !cursorElement || !isTyping) {
      return;
    }

    function findLastTextNode(node: Node): null | Text {
      if (
        node.nodeType === Node.TEXT_NODE &&
        node.textContent &&
        node.textContent.trim() !== ""
      ) {
        return node as Text;
      }
      for (let i = node.childNodes.length - 1; i >= 0; i--) {
        const lastTextNode = findLastTextNode(node.childNodes[i]);
        if (lastTextNode) {
          return lastTextNode;
        }
      }
      return null;
    }

    function updateCursorPosition() {
      if (!textContainer || !cursorElement) {
        return;
      }

      const boxRect = textContainer.getBoundingClientRect();
      const lastTextNode = findLastTextNode(textContainer);

      if (lastTextNode?.textContent) {
        const range = document.createRange();
        range.setStart(lastTextNode, lastTextNode.textContent.length);
        range.collapse(true);

        const rangeRect = range.getBoundingClientRect();

        const cursorElementTop = rangeRect.top - boxRect.top;
        const cursorElementLeft = rangeRect.left - boxRect.left;
        cursorElement.style.top = `${cursorElementTop}px`;
        cursorElement.style.left = `${cursorElementLeft + 2}px`;
      }
    }

    const mutationObserver = new MutationObserver(() => {
      if (cursorElement) {
        updateCursorPosition();
      }
    });

    mutationObserver.observe(textContainer, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, [textContainerRef, isTyping]);

  return (
    <div className="relative" ref={textContainerRef}>
      <ReactMarkdown
        className="markdown-body"
        linkTarget="_blank"
        children={children}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[[rehypeHighlight, { ignoreMissing: true }]]}
      />

      {/* typewriter cursor */}
      {useMemo(() => {
        if (isTyping) {
          return (
            <div
              ref={cursorRef}
              className="my-cursor absolute translate-y-1"
            ></div>
          );
        }
        return null;
      }, [isTyping])}
    </div>
  );
};

export default MarkdownRender;
