import { useEffect, useState } from "react";

import { SendingBgIcon } from "@/components/icons";

const DotsComponent = () => {
  const [visibleDots, setVisibleDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleDots((prevVisibleDots) => (prevVisibleDots + 1) % 4);
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="relative flex w-[45px] items-center justify-center ">
      <SendingBgIcon />
      <div className="absolute top-2 text-white">
        {Array.from({ length: 3 }).map((_, index) => (
          <span
            key={index}
            className={`text-4xl ${
              visibleDots >= index + 1 ? "opacity-100" : "opacity-0"
            }`}
          >
            .
          </span>
        ))}
      </div>
    </div>
  );
};

export default DotsComponent;
