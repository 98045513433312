import { StateCreator } from "zustand";

import { UserInputMessage } from "@/types";

import { StateToPersist } from "./persist";
import { State } from "./root";

export type ChatSlice = {
  userInputMessage: UserInputMessage;
  shouldSendMessage: boolean;
  messageProcessing: boolean;
  incomingMessages: string;
  shouldAutoScroll: boolean;

  setUserInputMessage: (message: string, cache: boolean) => void;
  setShouldSendMessage: (shouldSendMessage: boolean) => void;
  setMessageProcessing: (messageProcessing: boolean) => void;
  setIncomingMessages: (incomingMessages: string) => void;
  setShouldAutoScroll: (shouldAutoScroll: boolean) => void;
};

export const createChatSlice: StateCreator<
  State,
  [],
  [
    ["zustand/devtools", never],
    ["zustand/persist", StateToPersist],
    ["zustand/immer", never],
  ],
  ChatSlice
> = (set) => {
  return {
    userInputMessage: { message: "", cache: false },
    shouldSendMessage: false,
    messageProcessing: false,
    incomingMessages: "",
    shouldAutoScroll: true,

    setUserInputMessage: (message: string, cache: boolean) =>
      set({ userInputMessage: { message, cache } }),
    setShouldSendMessage: (shouldSendMessage) => set({ shouldSendMessage }),
    setMessageProcessing: (messageProcessing) => set({ messageProcessing }),
    setIncomingMessages: (incomingMessages) => set({ incomingMessages }),
    setShouldAutoScroll: (shouldAutoScroll) => set({ shouldAutoScroll }),
  };
};
