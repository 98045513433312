import { getRecaptchaV3Token, getV2_token } from "./CAPTCHA";

export const handleUserSendMsgFactory =
  (socket: WebSocket): ((inputValue: string, cache: boolean) => void) =>
  async (inputValue: string, cache: boolean) => {
    if (!inputValue?.trim()) {
      return;
    }

    const body = {
      type: "chat_v3",
      content: {
        question: inputValue,
        cache,
        v3_token: undefined as undefined | string,
        v2_token: undefined as undefined | string,
      },
    };
    const v2_token = getV2_token();
    if (v2_token) {
      body.type = "chat_v2";
      body.content.v2_token = v2_token;
    } else {
      body.content.v3_token = await getRecaptchaV3Token("send_msg");
    }

    const stringifiedBody = JSON.stringify(body);

    socket.send(stringifiedBody);
  };
