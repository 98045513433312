import { RefObject } from "react";

export const scrollToBottom = (
  containerRef: RefObject<HTMLDivElement>
): void => {
  setTimeout(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, 0);
};
