import { ArrowRightIcon } from "@/components/icons";
import { BREAKWIDTH, Message } from "@/constants/constants";
import useWindowSize from "@/hooks/useWindowSize";
import { useRootState } from "@/state/root";

interface GuessQuestionProps {
  message: Message;
}

const GuessQuestion: React.FC<GuessQuestionProps> = ({ message }) => {
  const messages: string[] = JSON.parse(message.content);
  const [windowWidth] = useWindowSize();
  const [setUserInputMessage, setShouldSendMessage] = useRootState((state) => [
    state.setUserInputMessage,
    state.setShouldSendMessage,
  ]);

  const handleClickQuestion = (question: string) => {
    setUserInputMessage(question, true);
    setShouldSendMessage(true);
  };

  const questionList = messages.map((question, index) => {
    return (
      <div
        key={index}
        className="border-b border-b-ask-bg-color bg-white p-2 first:rounded-t-[10px] last:rounded-b-[10px] last:border-b-0"
      >
        <button
          onClick={() => handleClickQuestion(question)}
          key={index}
          className={
            "flex w-full items-center justify-between rounded-[5px] px-3 py-2 text-start text-sm font-semibold hover:bg-chat-bg"
          }
        >
          {question}
          <span className="flex-none">
            <ArrowRightIcon />
          </span>
        </button>
      </div>
    );
  });
  return (
    <div className={"-mt-5 mb-8 flex justify-start gap-3"}>
      {windowWidth > BREAKWIDTH && <div className="w-10 flex-none"></div>}
      <div className="mr-12 flex w-fit flex-col drop-shadow-questions-container">
        {questionList}
      </div>
    </div>
  );
};
export default GuessQuestion;
