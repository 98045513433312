import { useTranslation } from "react-i18next";

export const ChatHistory = ({
  visible,
  expand,
}: {
  visible: boolean;
  expand: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {visible && (
        <div
          className="mb-4 cursor-pointer text-center text-xs text-zinc-500"
          onClick={expand}
        >
          {t("view chat history")}
        </div>
      )}
    </>
  );
};
