import { v4 as uuid } from "uuid";

const getUserId = () => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    return userId;
  }
  const newUserId = uuid();
  localStorage.setItem("userId", newUserId);
  return newUserId;
};

export { getUserId };
