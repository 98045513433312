import { useState } from "react";

import { ReCaptchaIcon } from "@/components/icons";

const ReCaptchaBadge = () => {
  const [isShow, setIsShow] = useState(false);

  const handleMouseEnter = () => {
    setIsShow(true);
  };

  const handleMouseLeave = () => {
    setIsShow(false);
  };

  const handleClick = () => {
    setIsShow((prev) => !prev);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className="select-none flex-col rounded-lg bg-white p-1 opacity-50 shadow hover:opacity-100"
    >
      <div className="h-5 w-5">
        <ReCaptchaIcon width={20} height={20} />
      </div>

      {isShow && (
        <div className="text-[8px]">
          <a
            className="hover:underline"
            draggable={false}
            href="https://www.google.com/intl/en/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <span aria-hidden="true" role="presentation">
            {" "}
            -{" "}
          </span>
          <a
            className="hover:underline"
            draggable={false}
            href="https://www.google.com/intl/en/policies/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
        </div>
      )}
    </div>
  );
};

export default ReCaptchaBadge;
