interface SVGProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  fill?: string;
  height?: string | number;
  width?: string | number;
  gradientId?: string;
}

export const ReCaptchaIcon = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 600 600"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      {...props}
    >
      <path
        d="M600 298.83c-.01-4.3-.11-8.58-.31-12.84V43.06l-67.12 67.16C477.64 42.93 394.06-.04 300.46-.04c-97.42 0-183.96 46.52-238.67 118.57l110.02 111.25a145.91 145.91 0 0 1 44.57-50.04c19.21-14.99 46.43-27.26 84.07-27.26 4.55 0 8.06.54 10.64 1.54 46.65 3.68 87.08 29.44 110.89 66.82l-77.88 77.93c98.64-.39 210.08-.62 255.89.05"
        fill="#1c3aa9"
      />
      <path
        d="M298.71-.03c-4.31.02-8.58.12-12.84.31H43.07l67.13 67.16C42.95 122.41 0 206.02 0 299.68c0 97.47 46.5 184.07 118.51 238.8L229.69 428.4a145.737 145.737 0 0 1-50-44.59c-14.99-19.22-27.25-46.45-27.25-84.12 0-4.55.53-8.07 1.53-10.65 3.69-46.67 29.43-87.12 66.79-110.94l77.88 77.92c-.39-98.7-.62-210.2.05-256.04"
        fill="#4285f4"
      />
      <path
        d="M.01 299.68c.02 4.3.12 8.58.31 12.84v242.93l67.12-67.16c54.94 67.29 138.51 110.26 232.12 110.26 97.41 0 183.95-46.52 238.66-118.57L428.2 368.73c-10.78 19.96-26.1 37.09-44.57 50.04-19.21 14.99-46.42 27.26-84.07 27.26-4.55 0-8.06-.54-10.64-1.54-46.64-3.68-87.08-29.44-110.88-66.82l77.88-77.93c-98.65.39-210.08.62-255.9-.05"
        fill="#ababab"
      />
    </svg>
  );
};

export const ArrowRightIcon = (props: SVGProps) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.28571 1L9 5.25595M9 5.25595L4.28571 9.5119M9 5.25595H0"
        stroke="#646E73"
        strokeWidth="2"
      />
    </svg>
  );
};

export const ChatBubbleIcon = (props: SVGProps) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_554_811)">
        <circle cx="31" cy="31" r="30" fill="#0F82FF" />
        <path
          d="M13 26C13 21.5817 16.5817 18 21 18H42C46.4183 18 50 21.5817 50 26V36C50 40.4183 46.4183 44 42 44H14C13.4477 44 13 43.5523 13 43V26Z"
          fill="white"
        />
        <path
          d="M20 26H40.8888"
          stroke="#418BFF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 33.1064H30.4444"
          stroke="#418BFF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_554_811"
          x="0"
          y="0"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_554_811"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_554_811"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const LanguageIcon = (props: SVGProps) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46244 0 5.5C0 8.53757 2.46243 11 5.5 11C8.53757 11 11 8.53757 11 5.5ZM1.32116 3.82726C1.77785 2.6874 2.6874 1.77785 3.82726 1.32116C3.55982 1.83743 3.35003 2.48112 3.20863 3.20863C2.48112 3.35003 1.83743 3.55982 1.32116 3.82726ZM7.17274 1.32116C7.44018 1.83743 7.64997 2.48112 7.79137 3.20863C8.51888 3.35003 9.16257 3.55982 9.67884 3.82726C9.22215 2.6874 8.3126 1.77785 7.17274 1.32116ZM1 5.5C1 5.05037 1.81434 4.5625 3.05724 4.2691C3.01953 4.66541 3 5.0775 3 5.5C3 5.9225 3.01953 6.33459 3.05724 6.7309C1.81434 6.4375 1 5.94963 1 5.5ZM8 5.5C8 5.9225 7.98047 6.33459 7.94276 6.7309C9.18566 6.4375 10 5.94963 10 5.5C10 5.05037 9.18566 4.5625 7.94276 4.2691C7.98047 4.66541 8 5.0775 8 5.5ZM1.32116 7.17274C1.83743 7.44018 2.48112 7.64997 3.20863 7.79137C3.35003 8.51888 3.55982 9.16257 3.82726 9.67884C2.6874 9.22215 1.77785 8.3126 1.32116 7.17274ZM7.79137 7.79137C7.64997 8.51888 7.44018 9.16257 7.17274 9.67884C8.3126 9.22215 9.22215 8.3126 9.67884 7.17274C9.16257 7.44018 8.51888 7.64997 7.79137 7.79137ZM4 5.5C4 5.00487 4.03105 4.52986 4.08631 4.08631C4.52986 4.03106 5.00488 4 5.50001 4C5.99513 4 6.47015 4.03105 6.91369 4.08631C6.96895 4.52986 7 5.00487 7 5.5C7 5.99513 6.96895 6.47014 6.91369 6.91369C6.47015 6.96894 5.99513 7 5.50001 7C5.00488 7 4.52986 6.96894 4.08631 6.91369C4.03105 6.47014 4 5.99513 4 5.5ZM4.2691 7.94276C4.5625 9.18566 5.05037 10 5.5 10C5.94962 10 6.4375 9.18566 6.7309 7.94276C6.33459 7.98048 5.9225 8 5.50001 8C5.07751 8 4.66541 7.98048 4.2691 7.94276ZM5.5 1C5.05038 1 4.5625 1.81434 4.2691 3.05724C4.66541 3.01952 5.07751 3 5.50001 3C5.9225 3 6.33459 3.01952 6.7309 3.05724C6.4375 1.81434 5.94962 1 5.5 1Z"
        fill="#0F82FF"
      />
    </svg>
  );
};

export const MinimizeIcon = (props: SVGProps) => {
  return (
    <svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="4" fill="#9CA3AF" />
    </svg>
  );
};

export const RobotIcon = (props: SVGProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#DDE2F0" />
      <ellipse cx="20.5001" cy="22" rx="12.7444" ry="12.3333" fill="#B3D7FF" />
      <path
        d="M7 19.5C7 15.9196 8.42232 12.4858 10.9541 9.95406C13.4858 7.42232 16.9196 6 20.5 6C24.0804 6 27.5142 7.42232 30.0459 9.95406C32.5777 12.4858 34 15.9196 34 19.5H33.0949C33.0949 16.1596 31.768 12.9561 29.406 10.594C27.0439 8.23204 23.8404 6.90508 20.5 6.90508C17.1596 6.90508 13.9561 8.23204 11.594 10.594C9.23204 12.9561 7.90508 16.1596 7.90508 19.5H7Z"
        fill="#0F82FF"
      />
      <path
        d="M26.3555 25.3333C26.3555 26.0775 26.2041 26.8144 25.9098 27.5019C25.6155 28.1894 25.1842 28.8141 24.6405 29.3403C24.0967 29.8665 23.4512 30.2839 22.7408 30.5686C22.0304 30.8534 21.2689 31 20.5 31C19.731 31 18.9696 30.8534 18.2591 30.5686C17.5487 30.2839 16.9032 29.8665 16.3595 29.3403C15.8157 28.8141 15.3844 28.1894 15.0901 27.5019C14.7959 26.8144 14.6444 26.0775 14.6444 25.3333L20.5 25.3333H26.3555Z"
        fill="white"
      />
      <path
        d="M33.6 24C33.6 25.2839 33.3162 26.5529 32.7675 27.7218C32.2189 28.8908 31.4182 29.9326 30.4192 30.7773C29.4202 31.6221 28.246 32.2502 26.9754 32.6196C25.7048 32.9889 24.3673 33.0909 23.0526 32.9187L23.1353 32.3271C24.3628 32.4878 25.6116 32.3926 26.7979 32.0478C27.9842 31.7029 29.0805 31.1165 30.0133 30.3278C30.946 29.539 31.6936 28.5663 32.2059 27.4749C32.7181 26.3835 32.9831 25.1987 32.9831 24H33.6Z"
        fill="#0F82FF"
      />
      <rect
        x="7"
        y="18"
        width="3.44444"
        height="6.66667"
        rx="1.72222"
        fill="#0F82FF"
      />
      <rect
        x="24.6444"
        y="31.6667"
        width="1.33333"
        height="2.75556"
        rx="0.666667"
        transform="rotate(90 24.6444 31.6667)"
        fill="#0F82FF"
      />
      <rect
        x="31"
        y="18"
        width="3.44444"
        height="6.66667"
        rx="1.72222"
        fill="#0F82FF"
      />
    </svg>
  );
};

export const SendIcon = (props: SVGProps) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#0F82FF" />
      <path
        d="M19.7612 26.4375L34.4278 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4278 12L25.7612 35.625C25.7027 35.7507 25.6087 35.8571 25.4906 35.9318C25.3724 36.0065 25.2349 36.0461 25.0945 36.0461C24.9541 36.0461 24.8166 36.0065 24.6984 35.9318C24.5803 35.8571 24.4863 35.7507 24.4278 35.625L19.7612 26.4375L10.4278 21.8438C10.3002 21.7862 10.192 21.6937 10.1162 21.5774C10.0403 21.461 10 21.3257 10 21.1875C10 21.0493 10.0403 20.914 10.1162 20.7976C10.192 20.6813 10.3002 20.5888 10.4278 20.5312L34.4278 12Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SendingBgIcon = (props: SVGProps) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#B3D7FF" />
    </svg>
  );
};
