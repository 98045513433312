import { v4 as uuid } from "uuid";

import { Message, Sender } from "@/constants/constants";

export const userMessageFactory = (content: string): Message => ({
  content,
  sender: Sender.USER,
  id: uuid(),
});

export const botMessageFactory = (
  content: string,
  type: "answer" | "question" = "answer"
): Message => ({
  content,
  sender: Sender.BOT,
  id: uuid(),
  type,
});

export const localMsgFactory = (
  content: string,
  sender: string,
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
  type: "answer" | "question" = "answer"
) => {
  if (sender === Sender.USER) {
    const userMessage = userMessageFactory(content);
    setMessages((prevMessages: Message[]) => [...prevMessages, userMessage]);
  } else {
    const botMessage = botMessageFactory(content, type);
    setMessages((prevMessages: Message[]) => [...prevMessages, botMessage]);
  }
};

export const questionListMsgFactory = (
  questions: string[],
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
) => {
  localMsgFactory(
    JSON.stringify(questions),
    Sender.BOT,
    setMessages,
    "question"
  );
};
