import axios from "axios";

import { BASE_URL, TOKEN } from "../constants/constants";

// set axios auth header
axios.defaults.headers.common["Authorization"] = TOKEN;

export const getChatHistory = async (user_id: string) => {
  const { data } = await axios.get(`${BASE_URL}/history/${user_id}`);
  return data;
};
