import { PersistOptions } from "zustand/middleware";

import { UserInputMessage } from "@/types";

import { State } from "./root";

export type StateToPersist = {
  userInputMessage: UserInputMessage;
};

export const persistOptions: PersistOptions<State, StateToPersist> = {
  name: "persisted-state",
  partialize: (state) => ({
    userInputMessage: state.userInputMessage,
  }),
};
