import { useTranslation } from "react-i18next";

import { MinimizeIcon } from "@/components/icons";

interface ChatHeaderProps {
  handleCloseBubble: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ handleCloseBubble }) => {
  const { t } = useTranslation();

  return (
    <div className="chat-header-shadow relative flex h-[40px] items-center justify-between p-3">
      <div className="text-[12px] font-[700] text-[#9CA3AF] ">
        {t("ai support")}
      </div>
      <button
        onClick={handleCloseBubble}
        className="flex h-6 w-6 items-center justify-center hover:opacity-80"
      >
        <MinimizeIcon />
      </button>
    </div>
  );
};

export default ChatHeader;
