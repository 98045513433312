import i18n from "i18next";

import {
  Message,
  reCaptchaV2SiteKey,
  reCaptchaV3SiteKey,
  Sender,
} from "@/constants/constants";

import { localMsgFactory } from "./messageFactory";

let v2_toekn: string | undefined = undefined;

export const getV2_token = () => v2_toekn;
export const setV2_token = (token: string | undefined) => (v2_toekn = token);

export const getRecaptchaV3Token = async (
  action: string
): Promise<string | undefined> => {
  const grecaptcha = window.grecaptcha;
  const siteKey = reCaptchaV3SiteKey;

  if (!grecaptcha) {
    return undefined;
  }

  await new Promise<void>((resolve) => {
    grecaptcha.enterprise.ready(() => {
      resolve();
    });
  });

  const token = await grecaptcha.enterprise.execute(siteKey, { action });
  return token;
};

export const renderRecaptchaV2 = (
  reCAPTCHAv2Callback: (token: string) => void,
  reCAPTCHAv2ExpiredCallback: () => void
) => {
  const grecaptcha = window.grecaptcha;
  if (typeof grecaptcha === "undefined") {
    console.log("Error: grecaptcha is not defined");
    return;
  }
  grecaptcha.enterprise.ready(() => {
    grecaptcha.enterprise.render("recaptcha-container", {
      sitekey: reCaptchaV2SiteKey,
      theme: "light",
      callback: reCAPTCHAv2Callback,
      "expired-callback": reCAPTCHAv2ExpiredCallback,
    });
  });
};

export const resetNotARealHumanMsg = (
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
) => {
  // remove previous notARealHumanMsg
  setMessages((prevMessages: Message[]) => {
    return prevMessages.filter(
      (message) => message.content !== i18n.t("not a real humand")
    );
  });
  localMsgFactory(i18n.t("not a real humand"), Sender.BOT, setMessages);
};

export const addRecaptchaV2Container = (
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
) => {
  resetNotARealHumanMsg(setMessages);
  localMsgFactory("RECAPTCHACONTAINER", Sender.BOT, setMessages);
};

export const removeRecaptchaV2Container = (
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
) => {
  setMessages((prevMessages: Message[]) =>
    prevMessages.filter((message) => message.content !== "RECAPTCHACONTAINER")
  );
};
