import { useRootState } from "@/state/root";

import { Message } from "../constants/constants";
import { addRecaptchaV2Container, removeRecaptchaV2Container } from "./CAPTCHA";
import { botMessageFactory, questionListMsgFactory } from "./messageFactory";
import { scrollToBottom } from "./scrollToBottom";

interface EventData {
  type: string;
  content: string | string[];
}

export const handleReceiveBotMsgFactory = (
  socket: WebSocket,
  containerRef: React.RefObject<HTMLDivElement>,
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
  renderRecaptcha: () => void
): (() => void) => {
  let tmpStr = "";
  const eventQueue: EventData[] = [];
  let processingEvents = false;

  const processEventQueue = () => {
    if (processingEvents) {
      return; // Events are already being processed, exit the function
    }
    processingEvents = true; // Set the flag to indicate that events are being processed

    const processNextEvent = async () => {
      if (eventQueue.length === 0) {
        processingEvents = false; // Reset the flag when all events are processed
        return;
      }

      const eventData = eventQueue.shift() as EventData; // Get the next event from the queue
      console.log("processing: ", eventData);

      await handleEventType(eventData); // Handle the event based on its type

      processNextEvent(); // Process the next event recursively
    };

    processNextEvent(); // Start processing the event queue
  };

  const handleEventType = async (eventData: EventData) => {
    switch (eventData.type) {
      case "authorized":
        // Handle authorized event
        break;
      case "v2_req":
        handleV2ReqEvent();
        break;
      case "answer::start":
        useRootState.getState().setMessageProcessing(true);
        break;
      case "answer::body":
        await handleAnswerBodyEvent(eventData);
        break;
      case "answer::end":
        handleAnswerEndEvent();
        break;
      case "questions":
        handleQuestionsEvent(eventData);
        break;
    }
  };

  const handleV2ReqEvent = () => {
    // Remove the last message
    setMessages((prevMessages: Message[]) => prevMessages.slice(0, -1));

    removeRecaptchaV2Container(setMessages);
    addRecaptchaV2Container(setMessages);
    renderRecaptcha();
    useRootState.getState().setMessageProcessing(false);
  };

  const handleAnswerBodyEvent = async (eventData: EventData) => {
    const content = eventData.content as string;

    for (let i = 0; i < content.length; i++) {
      const typedContent = content.slice(i, i + 1);
      tmpStr += typedContent;

      setMessages((prevMessages) => {
        if (i % 5 === 0 && useRootState.getState().shouldAutoScroll) {
          scrollToBottom(containerRef);
        }

        if (prevMessages.length === 0) {
          return [botMessageFactory(tmpStr)];
        } else {
          const lastMessage = prevMessages[prevMessages.length - 1];
          const updatedLastMessage = { ...lastMessage, content: tmpStr };
          return [...prevMessages.slice(0, -1), updatedLastMessage];
        }
      });

      // Add a delay between each character (adjust the delay time as needed)
      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  };

  const handleAnswerEndEvent = () => {
    useRootState.getState().setMessageProcessing(false);
    tmpStr = "";
  };

  const handleQuestionsEvent = (eventData: EventData) => {
    questionListMsgFactory(eventData.content as string[], setMessages);
  };

  // Event listener for user scrolling
  const handleScroll = () => {
    if (containerRef.current) {
      const isAtBottom =
        containerRef.current.scrollHeight - containerRef.current.scrollTop ===
        containerRef.current.clientHeight;
      useRootState.getState().setShouldAutoScroll(isAtBottom);
    }
  };

  const eventHandler = (event: MessageEvent) => {
    const eventData: EventData = JSON.parse(event.data);
    eventQueue.push(eventData);

    processEventQueue(); // Start processing the event stack
  };

  socket.addEventListener("message", eventHandler);

  // Attach the scroll event listener
  containerRef.current?.addEventListener("scroll", handleScroll);

  return () => {
    socket.removeEventListener("message", eventHandler);
    containerRef.current?.removeEventListener("scroll", handleScroll);
  };
};
