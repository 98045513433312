export const saveLang = (lang: string) => {
  localStorage.setItem("chatbot-language", lang);
};

export const getLang = () => {
  const savedLang = localStorage.getItem("chatbot-language");
  const browserLang = navigator.language;
  const validLang = ["en", "ja"];

  if (savedLang && validLang.includes(savedLang)) {
    return savedLang;
  }

  if (validLang.includes(browserLang)) {
    return browserLang;
  }

  return "en";
};

export const updateServerLang = (socket: WebSocket) => {
  const language = getLang();
  const body = JSON.stringify({
    type: "switch_lang",
    content: {
      language,
    },
  });

  socket.send(body);
};
