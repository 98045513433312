import { RobotIcon } from "@/components/icons";
import { BREAKWIDTH, Message } from "@/constants/constants";
import useWindowSize from "@/hooks/useWindowSize";
import { useRootState } from "@/state/root";

import MarkdownRender from "./MarkdownRender";

interface ChatBotAnswerProps {
  message: Message;
  isLast: boolean;
}

const ChatBotAnswer: React.FC<ChatBotAnswerProps> = ({ message, isLast }) => {
  const [windowWidth] = useWindowSize();
  const [messageProcessing] = useRootState((state) => [
    state.messageProcessing,
  ]);

  return (
    <div className={"flex justify-start gap-3"}>
      {windowWidth > BREAKWIDTH && (
        <div>
          <RobotIcon />
        </div>
      )}

      <div
        className={
          "mb-8 mr-12 min-h-[40px] overflow-auto rounded-lg bg-white p-2  font-[400]"
        }
      >
        {message.content === "RECAPTCHACONTAINER" ? (
          <div id="recaptcha-container"></div>
        ) : (
          <MarkdownRender
            children={message.content}
            isTyping={isLast && messageProcessing}
          />
        )}
      </div>
    </div>
  );
};
export default ChatBotAnswer;
